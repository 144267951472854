import React, {useEffect} from 'react'
import styles from './Team.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Questions from '../../components/Questions/Questions'
import TeamMembers from '../../components/TeamMembers/TeamMembers'
import Callback from '../../components/Callback/Callback'
import Advantages from '../../components/Advantages/Advantages'



const Team = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

        return (
            <section 
            className={styles.main} 
            >
                <div className={styles.container}> 
                    <div className={styles.item}>
                        <h1 className={styles.title}>Наша команда</h1>
                        <BreadCrumbs
                            text={'О нас'}
                        />
                    </div>
                    <div className={styles.box}>
                        <h2 className={styles.subtitle}>Кто мы?</h2>
                        <p className={styles.text_box}>За долгие годы мы выполнили сотни работ. Опыт наших мастеров по отделке и нанесению декоративной штукатурки, венецианских покрытий, декоративных красок, достигает 10-15 лет. За это время сформировалась настоящая команда профессионалов, любящих свое дело, накопился опыт, который позволяет применять комплексный подход в решении самых сложных задач. </p>
                        <p className={styles.text_box}>Все работы по нанесению декоративной штукатурки на стены и отделки других поверхностей от нашей мастерской выполняются исключительно под ключ. Цена включает работу с материалами. Это изначально прозрачная схема, в результате чего заказчик четко представляет бюджет расходов, избавляется от мучительных изучений, поисков, покупки и доставки материалов. Также это исключает риски "всплытия" дополнительных и непредвиденных затрат.</p>
                    </div>
                    <TeamMembers
                        isOpen={isOpen}
                    />
                    <Questions/>
                    <Advantages/>
                    <Callback
                    popupOpen={popupOpen}
                />
                </div>
            </section>
        )
    }
    
    export default Team