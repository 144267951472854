import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Advantages/img_1.png'
import image_2 from '../../image/Advantages/img_2.png'
import image_3 from '../../image/Advantages/img_3.png'
import image_4 from '../../image/Advantages/img_4.png'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const Advantages = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
        >
            <div className={styles.container}>
            <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_1} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>ЦЕНА И КАЧЕТВО ЛУЧШЕЕ СООТНОШЕНИЕ</h3>
                            <p className={styles.item_text}>Мы не посредники – мы исполнители. Обращаясь к нам напрямую, Вы получаете самую низкую цену и максимальное качество, избегая услуг посредников. Проконсультируем и подберем самое выгодное предложения для Вас, гибкая система скидок для постоянных клиентов.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_2} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Многолетний опыт работы</h3>
                            <p className={styles.item_text}>С 2009 года нами было выполнено множество проектов. Наши мастера выполняют работы любой сложности, от простой до сложнейших композиций с применением авторской техники. Наша работа будет выполнена с максимальной квалификацией и в соответствии со всеми технологическими требованиями.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_3} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>Разнообразие техник и эффектов</h3>
                            <p className={styles.item_text}>Мы специализируемся на различных техниках декоративной штукатурки, таких как микроцемент, арт-бетон, шелк, карта мира, винтаж и имитации различных камней. Мы гарантируем, что в результате работы у вас будет уникальный дизайн, соответствующий вашим потребностям и вкусам.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <img className={styles.icon} src={image_4} alt='телефон'/>
                            </div>
                            <h3 className={styles.item_title}>даем гарантию на все работы</h3>
                            <p className={styles.item_text}>Мы предоставляем гарантию на все выполненные нами работы. Это дает нашим клиентам дополнительное спокойствие и уверенность в её качестве. Мы стремимся к долгосрочным отношениям с нашими клиентами. Ценим время наших клиентов и достигнутые договоренности.</p>
                        </motion.li>
                    </motion.ul>
                    <motion.div 
                        className={styles.box_link}  
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <Link className={styles.link} to="/o-nas">О НАШЕЙ КОМАНДЕ</Link>
                        <Link className={styles.link} to="/portfolio">НАШИ РАБОТЫ</Link>
                    </motion.div>
            </div>   
        </motion.section>
        
        
    )
}

export default Advantages