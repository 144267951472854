import React, { useState, useCallback, useEffect} from 'react'
import { Route, Routes} from 'react-router-dom'
import app from './App.module.scss'
import { AppHeader } from '../AppHeader/AppHeader'
import Main  from '../../pages/Main/Main'
import { ModalForm } from '../ModalForm/ModalForm'
import { UpButton } from '../UpButton/UpButton'
import { MenuMobile } from '../MenuMobile/MenuMobile'
import { PageNotFound } from '../../pages/PageNotFound/PageNotFound'
import { PrivacyPolicy } from '../../pages/PrivacyPolicy/PrivacyPolicy'
import Footer  from '../Footer/Footer'
import Contacts  from '../../pages/Contacts/Contacts'
import OurServices from '../../pages/OurServices/OurServices'
import Team from '../../pages/Team/Team'
import Portfolio from '../../pages/Portfolio/Portfolio'
import Modal from '../Modal/Modal'
import ButtonCommunication from '../ButtonCommunication/ButtonCommunication'
import { ModalImage } from '../ModalImage/ModalImage'

import KartaMira from '../../pages/KartaMira/KartaMira'
import ArtBeton from '../../pages/ArtBeton/ArtBeton'
import Travertin from '../../pages/Travertin/Travertin'
import Mikrocement from '../../pages/Mikrocement/Mikrocement'
import VenecianskayaShtukaturka from '../../pages/VenecianskayaShtukaturka/VenecianskayaShtukaturka'
import Shyolk from '../../pages/Shyolk/Shyolk'
import Zamsha from '../../pages/Zamsha/Zamsha'
import EffektSkaly from '../../pages/EffektSkaly/EffektSkaly'
import Pesok from '../../pages/Pesok/Pesok'
import Vintazh from '../../pages/Vintazh/Vintazh'
import Rzhavchina from '../../pages/Rzhavchina/Rzhavchina'
import Trafaretnoe from '../../pages/Trafaretnoe/Trafaretnoe'


export const App = () =>{

  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isArr, setIsArr] = useState(null)

useEffect(() => {
  window.scrollTo(0, 0)
}, [])

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((arr) => {
      setIsPopupImageOpen(true)
      setIsArr(arr)
  }, [])


  return (
    <div className={app.page}>
    <AppHeader
      isOpen={handleOpenMenuMobile}
    />
      <Routes> 
        <Route  path='/'  element={
          <Main
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/uslugi'  element={
          <OurServices
            popupOpen={handleOpen}
            isOpen={handleFormOpen}
          />
        }/>
        <Route  path='/o-nas'  element={
          <Team
            isOpen={handleFormOpen}
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/portfolio'  element={
          <Portfolio
            popupOpen={handleOpen}
            isImageOpen={handleImageOpen}
          />
        }/>
        <Route  path='/kontakty'  element={
          <Contacts
            popupOpen={handleOpen}
          />
        }/>
        <Route path='*' element={
          <PageNotFound/>
                  }/>
        <Route  path='/privacy-policy'  element={
          <PrivacyPolicy/>
        }/>
        <Route  path='/karta-mira'  element={
          <KartaMira
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/art-beton'  element={
          <ArtBeton
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/travertin'  element={
          <Travertin
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/mikrocement'  element={
          <Mikrocement
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/venecianskaya-shtukaturka'  element={
          <VenecianskayaShtukaturka
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/shyolk'  element={
          <Shyolk
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/zamsha'  element={
          <Zamsha
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/effekt-skaly'  element={
          <EffektSkaly
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/pesok'  element={
          <Pesok
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/vintazh'  element={
          <Vintazh
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/rzhavchina'  element={
          <Rzhavchina
            popupOpen={handleOpen}
          />
        }/>
        <Route  path='/trafaretnoe'  element={
          <Trafaretnoe
            popupOpen={handleOpen}
          />
        }/>                
      </Routes>
      <Footer/>
      {isMenuMobile && <MenuMobile
        onClose={handleCloseMenuMobile}
      />}
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        arr={isArr}
      />}
      <UpButton/>
      <ButtonCommunication/>
    </div>
  )
}