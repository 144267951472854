import React from 'react'
import styles from './OurData.module.scss'


const OurData = () => {

    return (
        <div className={styles.data}>
            <div className={styles.data_box}>
                <p className={styles.data_text_rotate}>Более</p>
                <p className={styles.data_number}>15</p>
                <p className={styles.data_text}>лет на рынке</p>
            </div>
            <div className={styles.data_box}>
                <p className={styles.data_text_rotate}>Более</p>
                <p className={styles.data_number}>500</p>
                <p className={styles.data_text}>УСПЕШНЫХ ПРОЕКТОВ</p>
            </div>
            <div className={styles.data_box}>
                <p className={styles.data_text_rotate}>Более</p>
                <p className={styles.data_number}>5000</p>
                <p className={styles.data_text}>КВ.М. штукатурки</p>
            </div>
        </div>
    )
}

export default OurData