import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../KartaMira/KartaMira.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import img_1 from '../../image/Travertin/img_1.jpg'
import img_2 from '../../image/Travertin/img_2.jpg'
import img_3 from '../../image/Travertin/img_3.jpg'
import img_4 from '../../image/Travertin/img_4.jpg'
import img_5 from '../../image/Travertin/img_5.jpg'
import img_6 from '../../image/Travertin/img_6.jpg'
import img_7 from '../../image/Travertin/img_7.jpg'
import img_8 from '../../image/Travertin/img_8.jpg'
import img_9 from '../../image/Travertin/img_9.jpg'
import img_10 from '../../image/Travertin/img_10.jpg'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Travertin = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <div className={styles.item}>
                    <h1 className={styles.title}>Фактура "Эффект травертина"</h1>
                    <BreadCrumbs
                        text={'Эффект травертина'}
                    />
                </div>
                <div className={styles.box}>
                    <h2 className={styles.subtitle}>Примеры фактур "Эффект травертина"</h2>
                    <p className={styles.text_box}>Данный вид нанесения заслужил свое название из-за визуальной схожести с натуральным камнем. Для создания используют минеральную декоративную штукатурку. Покрытие высокопрочное и износостойкое.</p>
                    <p className={styles.text_box}>Полностью экологичное и отвечает всем требования пожарной безопасности.</p>
                    <p className={styles.text_box}>Широкий спектр цветов позволяет повторить натуральный полированный и античный мрамор любого происхождения и расцветок, комбинируя цвета между собой. В зависимости от нанесения возможна локальная реставрация покрытия, при повреждении во время эксплуатации.</p>
                    <p className={styles.text_box}><span>Подготовка стен перед нанесением:</span> требуется, подготовка стен «под покраску». Не допускаются трещины, сколы и т.д.</p>
                    <p className={styles.text_box}><span>Где используется:</span> на кухне, в прихожей, в гостиной, в спальне, в санузлах и ванных комнатах (за исключением мест прямого попадания воды, либо при усиленной защите лаками), в офисах и общественных местах, для создания акцентных стен, а так же как фоновое покрытие.</p> 
                </div>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_1} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 301</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_2} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 302</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_3} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 303</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_4} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 304</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_5} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 305</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_6} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 306</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_7} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 307</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_8} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 308</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_9} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 309</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_10} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 310</p>
                    </motion.li>
                </ul>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default Travertin