import React, {useEffect} from 'react'
import styles from './OurServices.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import ListWorks from '../../components/ListWorks/ListWorks'
import Callback from '../../components/Callback/Callback'
import Stages from '../../components/Stages/Stages'


const OurServices = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <div className={styles.item}>
                    <h1 className={styles.title}>Услуги и цены</h1>
                    <BreadCrumbs
                        text={'Услуги и цены'}
                    />
                </div>
                <div className={styles.box}>
                    <h2 className={styles.subtitle}>Что представляют собой услуги по нанесению декоративной штукатурки?</h2>
                    <p className={styles.text_box}>Декоративная штукатурка позволяет нанести на стены, полы, потолки и различные элементы интерьера покрытие различной цветовой гаммы и текстуры, при помощи которого можно создавать узоры и оптические эффекты, придающие интерьеру уникальности и презентабельности. Отделка стен декоративной штукатуркой позволяет создать имитацию металла, натурального камня, бетона, шелка, выветрившегося кирпича,  и прочих эффектов, способных воплотить в жизнь даже самую необычную дизайнерскую идею.</p>
                    <p className={styles.text_box}>Обладая хорошими пластическими характеристиками, декоративная штукатурка не только придает стенам и потолкам особый эстетичный вид, но и прекрасно выравнивает их поверхности, сглаживая неровности и скрывая другие дефекты.</p>
                    <p className={styles.text_box}>На сегодняшний день наша студия выполняет объем работ способный удовлетворить запросы самых требовательных клиентов.</p>
                    <p className={styles.text_box}><span>Все цены, на нашем сайте, указаны с учетом стоимости материалов. Возможно нанесение вашего материала. Также можем оказать помощь в подборе и приобретении  материала ведущих производителей со скидкой до 15%.</span></p>
                </div>
                <ListWorks/>
                <Stages/>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default OurServices