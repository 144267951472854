import React, { useState} from 'react'
import { Link } from 'react-router-dom'
import header from './header.module.scss'
import logo from '../../image/Header/logo.svg'
import phone from '../../image/Header/phone.svg'

export const AppHeader = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);


    return (
    <header className={`${header.header} ${visible && header.header_scroll}`}>
        <div className={header.box}>
            <Link className={header.logo_link} to='/'>
                <img className={header.logo} src={logo} alt = 'логотип'/>
            </Link>
            <nav className={header.nav}>
                <Link className={header.link} to="/">Главная</Link>
                <Link className={header.link} to="/o-nas">О нас</Link>
                <Link className={header.link} to="/uslugi">Услуги</Link>
                <Link className={header.link} to="/portfolio">Портфолио</Link>
                <Link className={header.link} to="/kontakty">Контакты</Link>
            </nav>
            <div className={header.contacts}>
                <img className={header.icon} src={phone} alt = 'телефон'/>
                <a className={header.phone}  href="tel:+79032052626" target='_blank' rel='noopener noreferrer'>+7 903 <span>205-26-26</span></a>
            </div>
            <button className={header.button} type='button' onClick={isOpen} aria-label='Открыть меню'/>
        </div>
    </header>
    );
};