import React from 'react'
import { motion } from "framer-motion"
import styles from './TeamMembers.module.scss'
import img_1 from '../../image/TeamMembers/img_1.jpg'
import img_2 from '../../image/TeamMembers/img_2.jpg'
import img_3 from '../../image/TeamMembers/img_3.jpg'
import img_4 from '../../image/TeamMembers/img_4.jpg'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const TeamMembers = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.box}>
                <motion.h2 className={styles.subtitle} variants={titleAnimation}>Наша Команда</motion.h2>
                <p className={styles.text}>Наши мастера по нанесению декоративной штукатурки  на стенах, потолках и других поверхностях (панно, лепнина и т.д.) проходят тщательный отбор и имеют опыт работы с декоративными покрытиями от 10 лет и более. Настоящие профессионалы сделают все возможное для достижения лучшего результата! Просто убедитесь в этом сами и закажите работу или бесплатную консультацию мастера для уточнения деталей заказа. Выбирайте лучших и получайте лучший результат!</p>
                <p className={styles.text}>Мастер по декоративной штукатурке - это почти как художник. И при ручном, и при механизированном нанесении декоративной штукатурки итоговая фактура зависит от движений руки мастера. Иногда по рисунку можно узнать мастера, который выполнял работу. Очень важна сохранять в себе творческое начало и чтобы оно не притуплялось на большом количестве заказов. </p>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_1} alt='дизайн'/>
                        <p className={styles.item_title}>Романцов Юрий</p>
                        <p className={styles.item_text}>Руководитель проектов. Один из лучших мастеров Москвы, более 200 успешно завершенных объектов.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_2} alt='дизайн'/>
                        <p className={styles.item_title}>Дмитрий Репеко</p>
                        <p className={styles.item_text}>Мастер-декоратор. Авторский подход в создании идей эко стиля, скала, этно мотивы</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_3} alt='дизайн'/>
                        <p className={styles.item_title}>Иван Душкин</p>
                        <p className={styles.item_text}>Ведущий технолог, художник,креативный мастер, автор и реализатор идей для эксклюзивных интерьеров</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <img className={styles.item_image} src={img_4} alt='дизайн'/>
                        <p className={styles.item_title}>Вадим Савенков</p>
                        <p className={styles.item_text}>Колорист, мастер-декоратор, технолог</p>
                    </motion.li>
                </ul>
                
                <button className={styles.link} onClick={isOpen}>заказать звонок</button>
            </div>
        </motion.section>
    )
}

export default TeamMembers