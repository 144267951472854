import React, {useEffect} from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './ListWorks.module.scss'
import img_1 from '../../image/ListWorks/img_1.webp'
import img_2 from '../../image/ListWorks/img_2.webp'
import img_3 from '../../image/ListWorks/img_3.webp'
import img_4 from '../../image/ListWorks/img_4.webp'
import img_5 from '../../image/ListWorks/img_5.webp'
import img_6 from '../../image/ListWorks/img_6.webp'
import img_7 from '../../image/ListWorks/img_7.webp'
import img_8 from '../../image/ListWorks/img_8.webp'
import img_9 from '../../image/ListWorks/img_9.webp'
import img_10 from '../../image/ListWorks/img_10.webp'
import img_11 from '../../image/ListWorks/img_11.webp'
import img_12 from '../../image/ListWorks/img_12.webp'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const ListWorks = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
        className={styles.main}
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}> 
                    <motion.h2 className={styles.subtitle} variants={titleAnimation}>Наши услуги по нанесению декоративной штукатурки</motion.h2>
                    <motion.div className={styles.line} variants={titleAnimation}></motion.div>
                    <ul className={styles.list}>
                    <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_1} alt='дизайн'/>
                                <Link className={styles.box_link} to="/art-beton">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>
                            <p className={styles.item_title}>Арт-бетон</p>
                            <h3 className={styles.item_text}>От 3 000 ₽ м² (с материалом)</h3>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_3} alt='дизайн'/>
                                <Link className={styles.box_link} to="/mikrocement">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>
                            <p className={styles.item_title}>Микроцемент</p>
                            <h3 className={styles.item_text}>От 5 000 ₽ м² (с материалом)</h3>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_4} alt='дизайн'/>
                                <Link className={styles.box_link} to="/venecianskaya-shtukaturka">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Венецианская штукатурка</h3>
                            <p className={styles.item_text}>От 3 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_6} alt='дизайн'/>
                                <Link className={styles.box_link} to="/shyolk">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект шелка</h3>
                            <p className={styles.item_text}>От 2 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_7} alt='дизайн'/>
                                <Link className={styles.box_link} to="/zamsha">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект замши или велюра</h3>
                            <p className={styles.item_text}>От 2 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_8} alt='дизайн'/>
                                <Link className={styles.box_link} to="/travertin">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект травертина(камня)</h3>
                            <p className={styles.item_text}>От 3 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_2} alt='дизайн'/>
                                <Link className={styles.box_link} to="/effekt-skaly">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект скалы</h3>
                            <p className={styles.item_text}>От 7 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_9} alt='дизайн'/>
                                <Link className={styles.box_link} to="/karta-mira">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект Карта мира(островки)</h3>
                            <p className={styles.item_text}>От 3 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_5} alt='дизайн'/>
                                <Link className={styles.box_link} to="/trafaretnoe">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Трафаретное нанесение</h3>
                            <p className={styles.item_text}>От 5 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_12} alt='дизайн'/>
                                <Link className={styles.box_link} to="/pesok">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект песка</h3>
                            <p className={styles.item_text}>От 2 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_11} alt='дизайн'/>
                                <Link className={styles.box_link} to="/vintazh">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Винтаж</h3>
                            <p className={styles.item_text}>От 4 000 ₽ м² (с материалом)</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_10} alt='дизайн'/>
                                <Link className={styles.box_link} to="/rzhavchina">
                                    <p className={styles.item_link} >подробнее</p>
                                </Link>
                            </div>    
                            <h3 className={styles.item_title}>Эффект ржавчины</h3>
                            <p className={styles.item_text}>От 3 000 ₽ м² (с материалом)</p>
                        </motion.li>
                    </ul>
            </div>
        </motion.section>
    )
}

export default ListWorks