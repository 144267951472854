import React, {useEffect} from 'react'
import styles from './Portfolio.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import PortfolioList from '../../components/PortfolioList/PortfolioList'

const Portfolio = ({popupOpen, isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <div className={styles.item}>
                    <h1 className={styles.title}>Примеры наших работ</h1>
                    <BreadCrumbs
                        text={'Портфолио'}
                    />
                </div>
                <PortfolioList
                    isImageOpen={isImageOpen}
                />
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default Portfolio