import React, {useEffect} from 'react'
import styles from './Main.module.scss'
import { InitialPage } from '../../components/InitialPage/InitialPage'
import ListWorks from '../../components/ListWorks/ListWorks'
import Advantages from '../../components/Advantages/Advantages'
import Stages from '../../components/Stages/Stages'
import AboutUs from '../../components/AboutUs/AboutUs'
import Callback from '../../components/Callback/Callback'

const Main = ({isOpen, popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <AboutUs/>
            <ListWorks/>
            <Advantages/>
            <Stages/>
            <Callback
                    popupOpen={popupOpen}
                />
        </section>
    )
}

export default Main