import React, {useEffect, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Portfolio.module.scss'
import { arrOffices } from '../../utils/data'
import { arrTrading } from '../../utils/data'
import { arrKitchens } from '../../utils/data'
import { arrBedrooms } from '../../utils/data'
import { arrLivingRooms } from '../../utils/data'
import { arrBathrooms } from '../../utils/data'
import img_1 from '../../image/Portfolio/Offices/img_1.jpg'
import img_2 from '../../image/Portfolio/Trading/img_1.jpg'
import img_3 from '../../image/Portfolio/Kitchens/img_1.jpg'
import img_4 from '../../image/Portfolio/Bedrooms/img_1.jpg'
import img_5 from '../../image/Portfolio/Bathrooms/img_1.jpg'
import img_6 from '../../image/Portfolio/LivingRooms/img_1.jpg'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const PortfolioList = ({isImageOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const doubleClick = useCallback(() => {
        isImageOpen(arrOffices)
    }, [isImageOpen])


    const tradingClick = useCallback(() => {
        isImageOpen(arrTrading)
    }, [isImageOpen])

    const kitchensClick = useCallback(() => {
        isImageOpen(arrKitchens)
    }, [isImageOpen])

    const bedroomsClick = useCallback(() => {
        isImageOpen(arrBedrooms)
    }, [isImageOpen])

    const livingRoomsClick = useCallback(() => {
        isImageOpen(arrLivingRooms)
    }, [isImageOpen])

    const bathroomsClick = useCallback(() => {
        isImageOpen(arrBathrooms)
    }, [isImageOpen])

    return (
        <motion.section 
        className={styles.main}
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}> 
                    <motion.h2 className={styles.subtitle} variants={titleAnimation}>Наши работы по нанесению декоративной штукатурки</motion.h2>
                    <motion.div className={styles.line} variants={titleAnimation}></motion.div>
                    <ul className={styles.list}>
                    <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}}
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_1} alt='дизайн' />
                                <div className={styles.box_link} onClick={doubleClick}>
                                    <p className={styles.item_link} >подробнее</p>
                                </div>
                            </div>
                            <p className={styles.item_title}>Офисные помещения</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_2} alt='дизайн'/>
                                <div className={styles.box_link} onClick={tradingClick}>
                                    <p className={styles.item_link} >подробнее</p>
                                </div>
                            </div>
                            <p className={styles.item_title}>Торговые помещения</p>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_4} alt='дизайн'/>
                                <div className={styles.box_link} onClick={bedroomsClick}>
                                    <p className={styles.item_link} >подробнее</p>
                                </div>
                            </div>    
                            <h3 className={styles.item_title}>Спальни</h3>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_5} alt='дизайн'/>
                                <div className={styles.box_link} onClick={bathroomsClick}>
                                    <p className={styles.item_link} >подробнее</p>
                                </div>
                            </div>    
                            <h3 className={styles.item_title}>Ванные</h3>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_6} alt='дизайн'/>
                                <div className={styles.box_link} onClick={livingRoomsClick}>
                                    <p className={styles.item_link} >подробнее</p>
                                </div>
                            </div>    
                            <h3 className={styles.item_title}>Гостинные</h3>
                        </motion.li>
                        <motion.li 
                            className={styles.list_item} 
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.item_box}>
                                <img className={styles.item_image} src={img_3} alt='дизайн'/>
                                <div className={styles.box_link} onClick={kitchensClick}>
                                    <p className={styles.item_link} >подробнее</p>
                                </div>
                            </div>    
                            <h3 className={styles.item_title}>Кухни</h3>
                        </motion.li>
                    </ul>
            </div>
        </motion.section>
    )
}

export default PortfolioList