import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../KartaMira/KartaMira.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import img_1 from '../../image/Rzhavchina/img_1.jpg'
import img_2 from '../../image/Rzhavchina/img_2.jpg'
import img_3 from '../../image/Rzhavchina/img_3.jpg'
import img_4 from '../../image/Rzhavchina/img_4.webp'
import img_5 from '../../image/Rzhavchina/img_5.jpg'
import img_6 from '../../image/Rzhavchina/img_6.jpg'
import img_7 from '../../image/Rzhavchina/img_7.jpg'
import img_8 from '../../image/Rzhavchina/img_8.jpg'
import img_9 from '../../image/Rzhavchina/img_9.jpg'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Rzhavchina = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <div className={styles.item}>
                    <h1 className={styles.title}>Эффект ржавчины</h1>
                    <BreadCrumbs
                        text={'Эффект ржавчины'}
                    />
                </div>
                <div className={styles.box}>
                    <h2 className={styles.subtitle}>Примеры фактур "Эффект ржавчины"</h2>
                    <p className={styles.text_box}>Декоративное фактурное интерьерное покрытие. Уникальные материалы позволяют создавать эффекты ржавчины и окислов металлов, таких как медь, бронза, никель и другие. Возможны 2 варианта создания эффекта:</p>
                    <p className={styles.text_box}>	&#8226; Визуальный эффект металла и ржавчины можно создать с помощью красок и лессировок.</p>
                    <p className={styles.text_box}>	&#8226; Для большей реалистичности имеются материалы в составе которых используется до 70% оксида железа (железная пыль). Это позволяет сделать визуальный эффект и настоящую металлическую поверхность.</p>
                    <p className={styles.text_box}>Под воздействием активатора создается настоящая ржавчина. При этом используется естественный процесс окисления.</p>
                    <p className={styles.text_box}>С помощью данного материала возможно не только декорирование стен, но и других предметов интерьера, например: лестничные поручни, статуи, столы, шкафы, барные стойки и многое другое. Полностью экологичное покрытие, отвечает всем требованиям пожарной безопасности.</p>
                    <p className={styles.text_box}><span>Подготовка стен перед нанесением:</span> не требуется, допускаются трещины, сколы и т.д.</p>
                    <p className={styles.text_box}><span>Где используется:</span> на кухне, в прихожей, в гостиной, в спальне, в санузлах и ванных комнатах (за исключением мест прямого попадания воды), в офисах и общественных местах, декорирование интерьерных вещей.</p> 
                </div>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_1} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 21</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_2} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 22</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_3} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 23</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_4} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 24</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_5} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 25</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_6} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 26</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_7} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 27</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_8} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 28</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_9} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 29</p>
                    </motion.li>
                </ul>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default Rzhavchina