import React, { useState } from 'react';
import styles from './Hint.module.scss'
import disactive from '../../image/Hint/disactive.svg'

export  const Hint = ({question, answer}) => {

    const [isActive, setIsActive] = useState(false);


    return (
        <li className={styles.hint}>
            <div className={styles.box} onClick={() => setIsActive(!isActive)}>
                <div className={styles.hint_box}>
                    <h3 className={styles.title}>{question}</h3>
                </div>
                <img className={!isActive ? styles.hint_image : styles.hint_active} src={disactive} alt='галочка'  onClick={() => setIsActive(!isActive)}/>
            </div>
            {isActive && (<p className={styles.text}>{answer}</p>)}
        </li>
    )
}