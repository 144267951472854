import React from 'react'
import styles from './Stages.module.scss'
import { motion } from "framer-motion"

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const Stages = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
                <motion.div className={styles.box}  variants={cardAnimation}>
                    <h2 className={styles.title}>Основные этапы взаимодействия<span> и проведения работ</span></h2> 
                </motion.div>   
            <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>01</p>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>02</p>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>После отправки заявки с вами свяжется менеджер с целью уточнить необходимые детали и согласовать время приезда мастера.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>03</p>
                            </div>
                            <h3 className={styles.item_title}>Выезд мастера</h3>
                            <p className={styles.item_text}>Замеряет площадь, консультирует по вариантам  возможных фактур и цветов, оценивает готовность поверхностей к нанесению штукатурки.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>04</p>
                            </div>
                            <h3 className={styles.item_title}>Составляем смету</h3>
                            <p className={styles.item_text}>Высылаем понятное коммерческое предложение со всеми этапами работ, необходимыми материалами и общей стоимостью.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>05</p>
                            </div>
                            <h3 className={styles.item_title}>Заключаем договор</h3>
                            <p className={styles.item_text}>После согласования обьема работ, необходимых материалов, образцов и сроков заключаем договор и клиент вносит предоплату.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>06</p>
                            </div>
                            <h3 className={styles.item_title}>Закупаем материалы</h3>
                            <p className={styles.item_text}>Чтобы вы не тратили на это свое время. Мы оказываем полный сервис по закупке необходимых материалов.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>07</p>
                            </div>
                            <h3 className={styles.item_title}>Выполняем работы</h3>
                            <p className={styles.item_text}>Как только образецы согласованы, все материалы закуплены, мы осуществляем выполнение оговоренных работ.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>08</p>
                            </div>
                            <h3 className={styles.item_title}>Сдача работ</h3>
                            <p className={styles.item_text}>По завершению работ наш менеджер проверяет качество у мастеров и производит сдачу обьекта вам.</p>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.05, once: true}} 
                            >
                            <div className={styles.box_image}>
                                <p className={styles.number}>09</p>
                            </div>
                            <h3 className={styles.item_title}>Финальная оплата</h3>
                            <p className={styles.item_text}>Вы производите финальную оплату выполненых работ. Мы высылаем вам памятку об уходе за покрытиями.</p>
                        </motion.li>
                    </motion.ul>
            </div>   
        </motion.section>
        
        
    )
}

export default Stages