import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './Questions.module.scss'
import {Hint} from '../../components/Hint/Hint'
import { arrQuestions } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}


const Questions = () => {

    return (
        <motion.section
            id='Voprosy'
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                    <div className={styles.container}>
                            <motion.h2 className={styles.subtitle} variants={titleAnimation}>Полезные советы от нас</motion.h2>
                            <motion.div className={styles.line} variants={titleAnimation}></motion.div>
                            <motion.ul className={styles.list} variants={titleAnimation}>
                                    {arrQuestions.map(item  => {
                                        const keyUid = uuidv4();
                                        return(
                                            <Hint
                                                question={item.question}
                                                answer={item.answer}
                                                key={keyUid}
                                        />
                                        )
                                    }   
                                        )}
                            </motion.ul>
                            <Link className={styles.link} to="/uslugi">наши услуги</Link>
                    </div>
        </motion.section>
        
    )
}

export default Questions