import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../KartaMira/KartaMira.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import img_1 from '../../image/Mikrocement/img_1.jpg'
import img_2 from '../../image/Mikrocement/img_2.jpg'
import img_3 from '../../image/Mikrocement/img_3.jpg'
import img_4 from '../../image/Mikrocement/img_4.jpg'
import img_5 from '../../image/Mikrocement/img_5.jpg'
import img_6 from '../../image/Mikrocement/img_6.jpg'
import img_7 from '../../image/Mikrocement/img_7.jpg'
import img_8 from '../../image/Mikrocement/img_8.jpg'
import img_9 from '../../image/Mikrocement/img_9.jpg'
import img_10 from '../../image/Mikrocement/img_10.jpg'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Mikrocement = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <div className={styles.item}>
                    <h1 className={styles.title}>Микроцемент</h1>
                    <BreadCrumbs
                        text={'Микроцемент'}
                    />
                </div>
                <div className={styles.box}>
                    <h2 className={styles.subtitle}>Примеры фактур "Микроцемент"</h2>
                    <p className={styles.text_box}>Микроцемент это материал с помощью которого можно получить покрытие не отличимое от натурального цемента /бетона на полах, стенах и потолках, а также фасадах зданий.</p>
                    <p className={styles.text_box}>За счет использования передовых компонентов, материалы которые используются при данном нанесении имеют очень высокую степень сцепления, поэтому могут использоваться также для отделки мебели, малых архитектурных форм, наноситься на поверхность металла, пластика, керамической плитки и других плотных поверхностей.</p>
                    <p className={styles.text_box}>Специально разработанные полиуретановые лаки и отвердители на основе полимеров последнего поколения придают покрытию из этих материалов исключительную прочность и износостойкость. Широкая гамма цветовых оттенков дает неограниченные возможности по применению.</p>
                    <p className={styles.text_box}>Покрытия из этих материалов характеризуются высокой прочностью, влагостойкостью, долговечностью и неприхотливы в эксплуатации. Без запаха, безопасны для людей и окружающей среды как при нанесении, так и при эксплуатации. Обладают противогрибковыми свойствами.</p>
                    <p className={styles.text_box}>Полностью экологичное и отвечает всем требования пожарной безопасности.</p>
                    <p className={styles.text_box}>Колеровка по веерам NCS, RAL, Tikkurilla. Подбор цвета под ваш образец.</p>
                    <p className={styles.text_box}><span>Подготовка стен перед нанесением:</span> требуется, подготовка стен «под покраску». Не допускаются трещины, сколы и т.д.</p>
                    <p className={styles.text_box}><span>Где используется:</span> на кухне, в прихожей, в гостинной, в спальне, в санузлах и ванных комнатах, бассейнах, в местах попадания воды, в офисах и общественных местах, декорирование интерьерных вещей. Очень прочное и влагостойкое покрытие.</p> 
                </div>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_1} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 11</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_2} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 12</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_3} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 13</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_4} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 14</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_5} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 15</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_6} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 16</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_7} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 17</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_8} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 18</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_9} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 19</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_10} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 22</p>
                    </motion.li>
                </ul>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default Mikrocement