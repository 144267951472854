import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/Initial/img_9.webp'
import title from '../../image/Title/img_1.webp'
import OurData from '../OurData/OurData'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}             
            >
            <div className={styles.box}>
                <motion.h2 className={styles.subtitle} variants={titleAnimation}>Лучшие решения, актуальный дизайн, непревзойденное качество — <span>все&#160;это&#160;о&#160;нашей&#160;студии</span></motion.h2>
                <motion.div className={styles.line} variants={titleAnimation}></motion.div>
                <div className={styles.container}>
                    <motion.img className={styles.image} src={image} alt='люди' variants={imageAnimation}/>
                    <img className={styles.image_title} src={title} alt='надпись'/>
                    <motion.div className={styles.box_info} variants={textAnimation}>
                        <p className={styles.text}>&#8195;С 2009 года нами было выполнено множество успешных проектов. За это время сформировалась настоящая команда профессионалов, любящих свое ремесло, накопился опыт, который позволяет применять комплексный подход в решении самых сложных задач. Наши мастера выполняют работы любой сложности, от простой до сложнейших композиций с применением авторской техники и методики. Мы предлагаем высокое качество, способное удовлетворить даже самого взыскательного заказчика. </p>
                        <p className={styles.text}>Мы работаем с помещениями любого типа и назначения: квартиры, коттедж, загородный дом, кафе или ресторан, офис, гостиница или бизнес-центр, бутик и многое другое.</p>
                        <p className={styles.text}>Мы не упустим ни один квадратный сантиметр, учтем все Ваши пожелания, добавим свои свежие идеи, познакомим с секретами современных решений и поможем создать в Вашем помещении утонченную роскошь!</p>
                        <OurData/>
                    </motion.div>
                </div>
                <Link className={styles.link} to="/uslugi">Услуги и цены</Link>
            </div>
        </motion.section>
    )
}

export default AboutUs