import React, {useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../KartaMira/KartaMira.module.scss'
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs'
import Callback from '../../components/Callback/Callback'
import img_1 from '../../image/Shyolk/img_1.jpg'
import img_2 from '../../image/Shyolk/img_2.jpg'
import img_3 from '../../image/Shyolk/img_3.jpg'
import img_4 from '../../image/Shyolk/img_4.jpg'
import img_5 from '../../image/Shyolk/img_5.jpg'
import img_6 from '../../image/Shyolk/img_6.jpg'
import img_7 from '../../image/Shyolk/img_7.jpg'
import img_8 from '../../image/Shyolk/img_8.jpg'
import img_9 from '../../image/Shyolk/img_9.jpg'
import img_10 from '../../image/Shyolk/img_10.jpg'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Shyolk = ({popupOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section 
        className={styles.main} 
        >
            <div className={styles.container}> 
                <div className={styles.item}>
                    <h1 className={styles.title}>Эффект шёлка</h1>
                    <BreadCrumbs
                        text={'Эффект шёлка'}
                    />
                </div>
                <div className={styles.box}>
                    <h2 className={styles.subtitle}>Примеры фактур "Эффект шёлка"</h2>
                    <p className={styles.text_box}>Это декоративное покрытие с имитацией натурального шелка. В зависимости от вида имеются отличия во внешнем виде и технических характеристиках. Например:</p>
                    <p className={styles.text_box}>&#8226; шелк бывает матовый либо с перламутровым отблеском и натуральными потертостями;</p>
                    <p className={styles.text_box}>&#8226; в некоторых видах шелков в составах имеется фиброволокно натурального шелка;</p>
                    <p className={styles.text_box}>&#8226; некоторые виды шелков обладают высокими показателями износостойкости, что позволяет использовать материал для декорирования зон повышенной проходимости (коридоры, салоны красоты, гостиницы, рестораны и т.д.);</p>
                    <p className={styles.text_box}>&#8226; Некоторые шелка устойчивы к влажной среде, не боятся прямого попадания влаги и не подвержены царапинам, при этом не требуют использования дополнительных защитных лаков в системе.</p>
                    <p className={styles.text_box}>Специально разработанные полиуретановые лаки и отвердители на основе полимеров последнего поколения придают покрытию из этих материалов исключительную прочность и износостойкость. Широкая гамма цветовых оттенков дает неограниченные возможности по применению.</p>
                    <p className={styles.text_box}>Шелк может выступать как самостоятельное покрытие, так с помощью него возможно создавать трафаретные узоры с рельефной выделкой и цветной комбинацией. Полностью экологичное и отвечает всем требования пожарной безопасности. Во время эксплуатации допускается влажная уборка.</p>
                    <p className={styles.text_box}>Полностью экологичное и отвечает всем требования пожарной безопасности.</p>
                    <p className={styles.text_box}>Колеровка по веерам NCS, RAL, Tikkurilla. Подбор цвета под ваш образец.</p>
                    <p className={styles.text_box}><span>Подготовка стен перед нанесением:</span> требуется, подготовка стен под покраску - идеально ровные и гладкие.</p>
                    <p className={styles.text_box}><span>Где используется:</span> на кухне, в прихожей, в гостиной, в спальне, в офисах и общественных местах.</p> 
                </div>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_1} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 11</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_2} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 12</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_3} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 13</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_4} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 14</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_5} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 15</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_6} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 16</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_7} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 17</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_8} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 18</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_9} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 19</p>
                    </motion.li>
                    <motion.li 
                        className={styles.list_item} 
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}} 
                        >
                        <div className={styles.item_box}>
                            <img className={styles.item_image} src={img_10} alt='штукатурка'/>
                        </div>    
                        <p className={styles.item_text}>Образец № 22</p>
                    </motion.li>
                </ul>
                <Callback
                    popupOpen={popupOpen}
                />
            </div>
        </section>
    )
}

export default Shyolk